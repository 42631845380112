<template>
  <!--薪酬审核-->
  <div>
    <div>
      <div style="display: flex;justify-content: space-between;align-items: center;">
        <div>
          <span style="font-size: 24px;font-weight: 500;color: #707070;">薪酬审核</span>
        </div>
        <div>
          <el-button style="background: #E4E5E9!important;border-color: #E4E5E9!important;color: #3F4157!important;" @click="returnPage()">返回</el-button>
        </div>
      </div>

      <el-card shadow="never" style="margin-top: 10px;">
        <el-form
            ref="form"
            :model="form"
            label-width="140px">

          <el-row :gutter="15" style="margin-top: 10px;">
            <el-col :span="6">
              <el-form-item label="姓名：">
                <span>{{form.staff_name}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="周期：">
                <span>{{form.period}}</span>
              </el-form-item>
            </el-col>
            <!--<el-col :span="6">-->
            <!--  <el-form-item label="月度营业额提成：">-->
            <!--    <span>300</span>-->
            <!--  </el-form-item>-->
            <!--</el-col>-->
            <!--<el-col :span="6">-->
            <!--  <el-form-item label="月度利润提成：">-->
            <!--    <span>5000</span>-->
            <!--  </el-form-item>-->
            <!--</el-col>-->
          </el-row>

          <!--<el-row :gutter="15">-->
          <!--  <el-col :span="6">-->
          <!--    <el-form-item label="考核奖罚：">-->
          <!--      <span>300</span>-->
          <!--    </el-form-item>-->
          <!--  </el-col>-->
          <!--  <el-col :span="6">-->
          <!--    <el-form-item label="无投诉奖励：">-->
          <!--      <span>300</span>-->
          <!--    </el-form-item>-->
          <!--  </el-col>-->
          <!--  <el-col :span="6">-->
          <!--    <el-form-item label="无催单奖励：">-->
          <!--      <span>500</span>-->
          <!--    </el-form-item>-->
          <!--  </el-col>-->
          <!--  <el-col :span="6">-->
          <!--    <el-form-item label="合格率奖励：">-->
          <!--      <span>5000</span>-->
          <!--    </el-form-item>-->
          <!--  </el-col>-->
          <!--</el-row>-->

          <!--<el-row :gutter="15">-->
          <!--  <el-col :span="6">-->
          <!--    <el-form-item label="合格薪资：">-->
          <!--      <span>5000</span>-->
          <!--    </el-form-item>-->
          <!--  </el-col>-->
          <!--</el-row>-->

          <div style="padding: 20px;background: #f7f8fa;border: 1px solid #eeeeee;border-radius: 10px;">
            <el-row :gutter="15">
              <el-col :span="6" v-for="(item,index) in form.column" :key="index">
                <el-form-item :label="item.label">
                  <el-input
                      placeholder="请输入"
                      v-model="item.value"
                      @blur="save"
                      clearable>
                  </el-input>
                </el-form-item>
              </el-col>
              <!--<el-col :span="6">-->
              <!--  <el-form-item label="岗位工资：">-->
              <!--    <el-input-->
              <!--        placeholder="请输入"-->
              <!--        v-model="form.input"-->
              <!--        clearable>-->
              <!--    </el-input>-->
              <!--  </el-form-item>-->
              <!--</el-col>-->
              <!--<el-col :span="6">-->
              <!--  <el-form-item label="利润奖金：">-->
              <!--    <el-input-->
              <!--        placeholder="请输入"-->
              <!--        v-model="form.input"-->
              <!--        clearable>-->
              <!--    </el-input>-->
              <!--  </el-form-item>-->
              <!--</el-col>-->
              <!--<el-col :span="6">-->
              <!--  <el-form-item label="项目工资：">-->
              <!--    <el-input-->
              <!--        placeholder="请输入"-->
              <!--        v-model="form.input"-->
              <!--        clearable>-->
              <!--    </el-input>-->
              <!--  </el-form-item>-->
              <!--</el-col>-->
            </el-row>

            <!--<el-row :gutter="15">-->
            <!--  <el-col :span="6">-->
            <!--    <el-form-item label="班次补助：">-->
            <!--      <el-input-->
            <!--          placeholder="请输入"-->
            <!--          v-model="form.input"-->
            <!--          clearable>-->
            <!--      </el-input>-->
            <!--    </el-form-item>-->
            <!--  </el-col>-->
            <!--  <el-col :span="6">-->
            <!--    <el-form-item label="全勤工资：">-->
            <!--      <el-input-->
            <!--          placeholder="请输入"-->
            <!--          v-model="form.input"-->
            <!--          clearable>-->
            <!--      </el-input>-->
            <!--    </el-form-item>-->
            <!--  </el-col>-->
            <!--  <el-col :span="6">-->
            <!--    <el-form-item label="项目奖金：">-->
            <!--      <el-input-->
            <!--          placeholder="请输入"-->
            <!--          v-model="form.input"-->
            <!--          clearable>-->
            <!--      </el-input>-->
            <!--    </el-form-item>-->
            <!--  </el-col>-->
            <!--  <el-col :span="6">-->
            <!--    <el-form-item label="月度奖金：">-->
            <!--      <el-input-->
            <!--          placeholder="请输入"-->
            <!--          v-model="form.input"-->
            <!--          clearable>-->
            <!--      </el-input>-->
            <!--    </el-form-item>-->
            <!--  </el-col>-->
            <!--</el-row>-->

            <!--<el-row :gutter="15">-->
            <!--  <el-col :span="6">-->
            <!--    <el-form-item label="取消薪资：" style="margin-bottom: 0!important;">-->
            <!--      <el-input-->
            <!--          placeholder="请输入"-->
            <!--          v-model="form.input"-->
            <!--          clearable>-->
            <!--      </el-input>-->
            <!--    </el-form-item>-->
            <!--  </el-col>-->
            <!--  <el-col :span="6">-->
            <!--    <el-form-item label="夜班全勤：" style="margin-bottom: 0!important;">-->
            <!--      <el-input-->
            <!--          placeholder="请输入"-->
            <!--          v-model="form.input"-->
            <!--          clearable>-->
            <!--      </el-input>-->
            <!--    </el-form-item>-->
            <!--  </el-col>-->
            <!--  <el-col :span="6">-->
            <!--    <el-form-item label="夜班奖励：" style="margin-bottom: 0!important;">-->
            <!--      <el-input-->
            <!--          placeholder="请输入"-->
            <!--          v-model="form.input"-->
            <!--          clearable>-->
            <!--      </el-input>-->
            <!--    </el-form-item>-->
            <!--  </el-col>-->
            <!--</el-row>-->
          </div>

          <div style="margin-top: 20px;">
            <span style="font-size: 18px;font-weight: 500;color: #202033;">合计应付工资：</span>
            <span style="font-size: 30px;font-weight: 700;color: #fea837;margin-left: 20px;">{{form.sum}}</span>
          </div>

          <!--<el-row :gutter="15" style="margin-top: 20px;">-->
          <!--  <el-col :span="4">-->
          <!--    <div>-->
          <!--      <div style="display: flex;align-items: center;">-->
          <!--        <span style="width: 5px;height: 16px;background: #a3a4b2;display: inline-block;margin-right: 10px;"></span>-->
          <!--        <span style="font-size: 18px;font-weight: 500;color: #3f4155;">报销凭证</span>-->
          <!--      </div>-->
          <!--      <div style="margin-top: 15px; width: 100%;height: 160px;">-->
          <!--        <img src="@/assets/images/home/caiwu/pingzheng.png" alt="" style="width: 100%;height: 100%;border-radius: 8px;">-->
          <!--      </div>-->
          <!--      <div style="text-align: center;margin-top: 20px;">-->
          <!--        <el-button style="background: #31334B!important;border-color: #31334B!important;color: #FFFFFF!important;margin-right: 20px;">合格</el-button>-->
          <!--        <el-button>不合格</el-button>-->
          <!--      </div>-->
          <!--    </div>-->
          <!--  </el-col>-->
          <!--  <el-col :span="4">-->
          <!--    <div>-->
          <!--      <div style="display: flex;align-items: center;">-->
          <!--        <span style="width: 5px;height: 16px;background: #a3a4b2;display: inline-block;margin-right: 10px;"></span>-->
          <!--        <span style="font-size: 18px;font-weight: 500;color: #3f4155;">报销凭证</span>-->
          <!--      </div>-->
          <!--      <div style="margin-top: 15px; width: 100%;height: 160px;">-->
          <!--        <img src="@/assets/images/home/caiwu/pingzheng.png" alt="" style="width: 100%;height: 100%;border-radius: 8px;">-->
          <!--      </div>-->
          <!--      <div style="text-align: center;margin-top: 20px;">-->
          <!--        <el-button style="background: #31334B!important;border-color: #31334B!important;color: #FFFFFF!important;margin-right: 20px;">合格</el-button>-->
          <!--        <el-button>不合格</el-button>-->
          <!--      </div>-->
          <!--    </div>-->
          <!--  </el-col>-->
          <!--  <el-col :span="4">-->
          <!--    <div>-->
          <!--      <div style="display: flex;align-items: center;">-->
          <!--        <span style="width: 5px;height: 16px;background: #a3a4b2;display: inline-block;margin-right: 10px;"></span>-->
          <!--        <span style="font-size: 18px;font-weight: 500;color: #3f4155;">报销凭证</span>-->
          <!--      </div>-->
          <!--      <div style="margin-top: 15px; width: 100%;height: 160px;">-->
          <!--        <img src="@/assets/images/home/caiwu/pingzheng.png" alt="" style="width: 100%;height: 100%;border-radius: 8px;">-->
          <!--      </div>-->
          <!--      <div style="text-align: center;margin-top: 20px;">-->
          <!--        <el-button style="background: #31334B!important;border-color: #31334B!important;color: #FFFFFF!important;margin-right: 20px;">合格</el-button>-->
          <!--        <el-button>不合格</el-button>-->
          <!--      </div>-->
          <!--    </div>-->
          <!--  </el-col>-->
          <!--  <el-col :span="4">-->
          <!--    <div>-->
          <!--      <div style="display: flex;align-items: center;">-->
          <!--        <span style="width: 5px;height: 16px;background: #a3a4b2;display: inline-block;margin-right: 10px;"></span>-->
          <!--        <span style="font-size: 18px;font-weight: 500;color: #3f4155;">报销凭证</span>-->
          <!--      </div>-->
          <!--      <div style="margin-top: 15px; width: 100%;height: 160px;">-->
          <!--        <img src="@/assets/images/home/caiwu/pingzheng.png" alt="" style="width: 100%;height: 100%;border-radius: 8px;">-->
          <!--      </div>-->
          <!--      <div style="text-align: center;margin-top: 20px;">-->
          <!--        <el-button style="background: #31334B!important;border-color: #31334B!important;color: #FFFFFF!important;margin-right: 20px;">合格</el-button>-->
          <!--        <el-button>不合格</el-button>-->
          <!--      </div>-->
          <!--    </div>-->
          <!--  </el-col>-->
          <!--  <el-col :span="4">-->
          <!--    <div>-->
          <!--      <div style="display: flex;align-items: center;">-->
          <!--        <span style="width: 5px;height: 16px;background: #a3a4b2;display: inline-block;margin-right: 10px;"></span>-->
          <!--        <span style="font-size: 18px;font-weight: 500;color: #3f4155;">报销凭证</span>-->
          <!--      </div>-->
          <!--      <div style="margin-top: 15px; width: 100%;height: 160px;">-->
          <!--        <img src="@/assets/images/home/caiwu/pingzheng.png" alt="" style="width: 100%;height: 100%;border-radius: 8px;">-->
          <!--      </div>-->
          <!--      <div style="text-align: center;margin-top: 20px;">-->
          <!--        <el-button style="background: #31334B!important;border-color: #31334B!important;color: #FFFFFF!important;margin-right: 20px;">合格</el-button>-->
          <!--        <el-button>不合格</el-button>-->
          <!--      </div>-->
          <!--    </div>-->
          <!--  </el-col>-->
          <!--  <el-col :span="4">-->
          <!--    <div>-->
          <!--      <div style="display: flex;align-items: center;">-->
          <!--        <span style="width: 5px;height: 16px;background: #a3a4b2;display: inline-block;margin-right: 10px;"></span>-->
          <!--        <span style="font-size: 18px;font-weight: 500;color: #3f4155;">报销凭证</span>-->
          <!--      </div>-->
          <!--      <div style="margin-top: 15px; width: 100%;height: 160px;">-->
          <!--        <img src="@/assets/images/home/caiwu/pingzheng.png" alt="" style="width: 100%;height: 100%;border-radius: 8px;">-->
          <!--      </div>-->
          <!--      <div style="text-align: center;margin-top: 20px;">-->
          <!--        <el-button style="background: #31334B!important;border-color: #31334B!important;color: #FFFFFF!important;margin-right: 20px;">合格</el-button>-->
          <!--        <el-button>不合格</el-button>-->
          <!--      </div>-->
          <!--    </div>-->
          <!--  </el-col>-->
          <!--</el-row>-->
        </el-form>
      </el-card>

      <div style="text-align: center;margin-top: 30px;">
        <!--<el-button style="background: #FFFFFF!important;border-color: #DCDCE0!important;color: #242832!important;margin-right: 20px;width: 240px!important;" @click="returnPage">取消</el-button>-->
        <el-button style="background: #FEA837!important;border-color: #FEA837!important;color: #FFFFFF!important;margin-right: 20px;width: 240px!important;" @click="shenhe">审核通过</el-button>
      </div>


    </div>

  </div>
</template>

<script>
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {staff_emolument_check, staff_emolument_details, staff_emolument_edit} from "@/api/finance";

export default {
  props:{
    data: Object
  },
  // 组件生效
  components: {

  },
  data(){
    return{
      form:{},
    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    console.log(this.data)

    //调用获取详情
    this.getDetails();
  },

  methods:{
    //点击返回按钮
    returnPage(){
      this.$emit('showDetails',false);
    },

    // 获取详情
    getDetails(){
      staff_emolument_details(this.data.id).then(res => {
        if(res.code == 200){
          this.form = res.data;
        }else {
          this.$message.error(res.msg);
        }
      })
    },

    //点击审核
    shenhe(){
      staff_emolument_check(this.data.id).then(res => {
        if(res.code == 200){
          this.$message.success(res.msg);
          this.$emit('showDetails',false);
        }else {
          this.$message.error(res.msg);
        }
      })
    },

    //失去焦点修改的数据
    save(){
      console.log(this.form)
      staff_emolument_edit(this.data.id,this.form).then(res => {
        if(res.code == 200){
          this.$message.success(res.msg);
          this.getDetails();
        }else {
          this.$message.error(res.msg);
        }
      })
    },


  }

}
</script>

<style lang="scss" scoped>

</style>
